.layout {
  padding: 20px 20vw 0;
}

.logo {
  width: 100px;
}

.nav__links {
  list-style-type: none;
}

.nav__link {
  text-decoration: none;
  font-size: 1.2rem;
  color: var(--red-900);
}

@media (max-width: 1600px) {
  .layout {
    padding: 20px 10vw 0;
  }
}

@media (max-width: 700px) {
  .layout {
    padding: 20px;
  }
}

@media (max-width: 500px) {
  .layout {
    padding: 0px;
  }
}
