.nav {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.nav img {
  width: 100px;
}

.nav a {
  text-decoration: none;
}

.nav__links {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.nav__link {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--grayscale-900);
  padding: 10px;
  transition: all 0.1s;
  cursor: pointer;
}

.nav__link:hover {
  color: var(--red-900);
  border-bottom: 2px solid var(--red-900);
}

.nav__button {
  background-color: var(--red-900);
  color: #fff;
  border-radius: 0.75rem;
  padding: 10px 15px;
}

.nav__button:hover {
  background-color: transparent;
  color: var(--red-900);
  border: 2px solid var(--red-900);
}

.mobile-menu {
  display: none;
  font-size: 2rem;
  color: var(--red-900);
  cursor: pointer;
  margin-right: 15px;
}

@media (max-width: 700px) {
  .mobile-menu {
    display: inline-block;
  }

  .nav__link {
    display: none;
  }
}
