.skill__container {
  padding: 8px;
  border-radius: 1rem;
  background: linear-gradient(to right, #fbb1b1, #ffdfdf);
}

.skill__content {
  padding: 50px;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skill__content img {
  width: 100%;
  max-width: 100px;
}

.skill__content h3 {
  margin-top: 10px;
  font-weight: 600;
  font-size: 1.5rem;
}
