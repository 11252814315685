* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --red-900: #950001;
  --red-700: #ea5455;
  --red-500: #f69090;
  --red-300: #fbb1b1;
  --red-100: #ffdfdf;

  --orange-900: #c35a24;
  --orange-700: #f07b3f;
  --orange-500: #fca275;
  --orange-300: #feb793;
  --orange-100: #ffd1ba;

  --blue-900: #0072be;
  --blue-700: #47b5ff;
  --blue-500: #8bd0ff;
  --blue-300: #b6e1ff;
  --blue-100: #e1f3ff;

  --grayscale-900: #06283d;
  --grayscale-700: #5a656b;
  --grayscale-500: #98999a;
  --grayscale-300: #c1c1c1;
  --grayscale-100: #e4e4e4;
}

body {
  font-family: "Poppins", sans-serif;
}

h3 {
  font-weight: 700;
}
