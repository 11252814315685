.h2 {
  margin: 70px 0;
  font-size: 3rem;
  font-weight: 600;
}

@media (max-width: 400px) {
  .h2 {
    margin-left: 10px;
  }
}
