.project__container {
  margin-top: 70px;
}

.project__header {
  display: flex;
  flex-direction: column;
}

.project__header small {
  font-size: 1.1rem;
  margin-bottom: 20px;
}

.project__heading {
  display: flex;
  align-items: center;
  gap: 20px;
}

.project__heading h1 {
  font-size: 3rem;
  font-weight: 600;
}

.project__heading img {
  width: 50px;
}

.project__content {
  margin: 100px 0 200px;
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  gap: 50px;
}

.project__content--reversed {
  grid-template-columns: 0.6fr 0.4fr;
}

.project__content--reversed .project__info {
  grid-column: 2;
  grid-row: 1;
}

.project__content img {
  width: 100%;
  border-radius: 1rem;
  transform: rotate(3deg);
  transition: transform 0.3s;
}

.project__content img:hover {
  transform: rotate(0deg);
}

.project__content--reversed img {
  grid-column: 1;
  grid-row: 1;
  transform: rotate(-3deg);
}

.project__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.project__info h3 {
  font-size: 2rem;
}

.project__info p {
  font-size: 1.2rem;
}

.project__actions {
  display: flex;
  justify-content: center;
}

.back-to-home {
  text-align: center;
  color: #fff;
  padding: 20px 50px;
  border-radius: 1rem;
  text-decoration: none;
  font-size: 1.5rem;
}

@media (max-width: 700px) {
  .project__content,
  .project__content--reversed {
    display: block;
  }

  .project__content img,
  .project__content--reversed img {
    transform: rotate(0deg);
  }
}

@media (max-width: 500px) {
  .project__content {
    padding: 15px;
  }

  .project__header {
    padding: 15px;
  }
}
