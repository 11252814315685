.about-img {
  width: 50%;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  position: relative;
  margin: 20px 20px 100px;
}

.about-desc::before {
  content: "❝";
  position: absolute;
  top: -30px;
  left: -30px;
  font-size: 4.5rem;
  opacity: 0.2;
}

.about-desc {
  /* color: #fff; */
  font-weight: 600;
  font-size: 1.5rem;
  text-align: left;
}

.hero__actions {
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.hero__btn {
  font-size: 1.5rem;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 1rem;
  transition: all 0.2s;
}

.hero__contact {
  background-color: var(--red-900);
  color: #fff;
  box-shadow: -10px 10px var(--red-500);
}

.hero__contact:hover {
  box-shadow: none;
}

.hero__resume {
  border: 2px solid var(--red-900);
  color: var(--red-900);
  box-shadow: -10px 10px var(--grayscale-900);
}

.hero__resume:hover {
  box-shadow: none;
}
