.hero__container {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero__container h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 32px;
}

.hero__info {
  color: var(--red-900);
}

.hero__info small {
  font-size: 1.5rem;
}

.hero__actions {
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.hero__btn {
  font-size: 1.5rem;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 1rem;
  transition: all 0.2s;
}

.hero__contact {
  background-color: var(--red-900);
  color: #fff;
  box-shadow: -10px 10px var(--red-500);
}

.hero__contact:hover {
  box-shadow: none;
}

.hero__resume {
  border: 2px solid var(--red-900);
  color: var(--red-900);
  box-shadow: -10px 10px var(--grayscale-900);
}

.hero__resume:hover {
  box-shadow: none;
}

.hero__img {
  width: 600px;
}

@media (max-width: 1500px) {
  .hero__img {
    width: 450px;
  }
}

@media (max-width: 700px) {
  .hero__container {
    flex-direction: column;
    align-items: start;
    padding: 20px;
    gap: 50px;
  }

  .hero__img {
    width: 300px;
  }
}
