.preview__container {
  margin: 50px 0;
  background-color: #cecece;
  padding: 50px;
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  grid-template-rows: repeat(3, 1fr);
  border-radius: 1rem;
  text-align: left;
}

.preview__container--reversed {
  text-align: right;
  grid-template-columns: 0.6fr 0.4fr;
}

.preview__title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.preview__container--reversed .preview__title {
  flex-direction: row-reverse;
}

.preview__icon {
  width: 50px;
}

.preview__container--dark h3 {
  color: #fff !important;
}

.preview__title h3 {
  font-size: 2rem;
  color: var(--grayscale-900);
}

.preview__container--dark .preview__summary {
  color: #fff !important;
}

.preview__summary {
  color: var(--grayscale-900);
}

.preview__img {
  grid-column: 2;
  grid-row: 1/4;
  width: 90%;
  justify-self: center;
  align-self: center;
  border-radius: 1rem;
  transform: rotate(2deg);
  transition: transform 0.1s;
}

.preview__container--reversed .preview__img {
  transform: rotate(-2deg);
}

.preview__img--reversed {
  grid-column: 1 !important;
}

.preview__img:hover {
  transform: rotate(0deg);
}

.preview__actions a {
  text-decoration: none;
}

.preview__actions {
  display: flex;
  gap: 20px;
}

.preview__container--reversed .preview__actions {
  justify-content: flex-end;
}

.preview__actions button {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  display: inline;
  padding: 10px 30px;
  border-radius: 1rem;
  border: none;
  cursor: pointer;
  transition: box-shadow 0.1s;
}

.btn__secondary {
  box-shadow: -7px 7px var(--grayscale-900);
}

.preview__actions button:hover {
  box-shadow: none !important;
}

@media (max-width: 1000px) {
  /* .preview__title h3 {
    font-size: 1rem;
  }

  .preview__summary {
    font-size: 0.7rem;
  } */

  .preview__container,
  .preview__container--reversed {
    padding: 15px;
    flex-direction: column;
    grid-template-rows: repeat(3, 1fr) 3fr;
    grid-template-columns: 1fr;
    border-radius: 0;
  }

  .preview__title {
    justify-content: center;
  }

  .preview__img {
    grid-row: auto;
    grid-column: auto;
  }

  .preview__summary {
    justify-content: center;
    text-align: center;
  }

  .preview__actions,
  .preview__container--reversed .preview__actions {
    justify-content: center;
  }

  .preview__img {
    transform: rotate(0deg) !important;
  }
}

@media (max-width: 400px) {
  .preview__container {
    grid-template-rows: repeat(3, 1fr) 2fr;
  }
}
